import React from 'react';

function Header() {
  return (
    <>
    <div className="collapse" id="navbarToggleExternalContent">
    <div className="bg-dark p-4">
     <h5 className="text-white h4">Collapsed content</h5>
     <span className="text-muted">Toggleable via the navbar brand.</span>
     </div>
     </div>
    <nav className="navbar navbar-dark bg-dark" data-bs-theme="dark">
     <div className="container-fluid">
      <a className="navbar-brand" href="#">Blog Scrapper</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
     {/* <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <a className="nav-link" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Contact</a>
          </li>
        </ul>
  </div> */}
       </div>
    </nav></>
  );
}

export default Header;