import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blogs from "./components/Blog";
//import Blogpost from "./components/Blog";
import FileUpload from "./components/Fileupload";
import Landing from "./components/Landing";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FileUpload />} />
        <Route path="/blogs" element={<Blogs />} />
        {/*<Route path="/blog/:id" element={<Blogpost />} /> */}
        <Route path="/landing" element={<Landing />}>
          {/* 
         <Route index element={<Home />} />
         <Route path="blogs" element={<Blogs />} />
         <Route path="contact" element={<Contact />} />
         <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
