import React, { useState,useEffect } from 'react';
import Header from './Header';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal, Button } from 'react-bootstrap';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [blogContent, setBlogContent] = useState({ title: '', content: '' });

  // Fetch the blog posts from the server
  useEffect(() => {
    fetch('http://localhost:5000/server/blog')
      .then(res => res.json())
      .then(data => setBlogs(data))
      .catch(error => console.log(error));
  }, []);

  // Fetch the full content of a blog post by its ID
  const fetchBlogContent = (id) => {
    fetch(`http://localhost:5000/server/blogarticle/${id}`)
      .then(res => res.json())
      .then(data => {
        setBlogContent({ title: data.title, content: data.content });
        console.log(blogContent) 
        setShowModal(true);
      })
      .catch(error => console.log(error));
  };

  return (
    <div>
      <Header />
      <h1 className="text-center mb-5">Blogs</h1>
      <Container>
        <Row>
          {blogs.map(blog => (
            <Col key={blog.id} md={4}>
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">{blog.title}</h5>
                  <p className="card-text">{blog.excerpt}</p>
                  <button className="btn btn-primary" onClick={() => fetchBlogContent(blog.id)}>Read more</button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal to display full blog content */
}

  <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{blogContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: blogContent.content}}></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Blogs;
