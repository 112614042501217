import React, { useState } from "react";
import { Modal, Button, Container, Form } from "react-bootstrap";
import Header from "./Header";

function UploadModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function FileUpload() {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [uploadStatus, setUploadStatus] = useState({
    show: false,
    message: "",
  });

  const handleFileChange = (event) => {
    const allowedExtensions = /(\.html|\.htm)$/i;
    const selectedFiles = event.target.files;

    const validFiles = [...selectedFiles].filter((file) =>
      allowedExtensions.test(file.name)
    );

    setSelectedFiles(validFiles);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (selectedFiles.length === 0) {
      alert("Please select at least one HTML file to upload.");
      return;
    }

    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append("file", file);
    }

    try {
      console.log("Starting file upload...");
      const response = await fetch(
        "https://www.digitaladvertising.systems:5000/server/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      console.log(`HTTP status: ${response.status}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Upload successful!");
      console.log(data);
      setUploadStatus({
        show: true,
        message: "Files uploaded successfully",
      });
    } catch (error) {
      console.error("Upload failed!");
      console.error(error);
      setUploadStatus({
        show: true,
        message: "Failed to upload files",
      });
    }
  };

  return (
    <div>
      <Header />
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{
          marginTop: "10%",
          border: "1px solid black",
          padding: "20px",
        }}
      >
        <div className="mx-auto">
          <Form onSubmit={handleFileUpload}>
            <Form.Group controlId="formFile" style={{ paddingBottom: "3%" }}>
              <Form.Label>Upload File(s)</Form.Label>
              <Form.Control type="file" multiple onChange={handleFileChange} />
            </Form.Group>
            <Button
              variant="primary danger"
              type="submit"
              disabled={!selectedFiles.length}
            >
              Upload
            </Button>

            <UploadModal
              show={uploadStatus.show}
              message={uploadStatus.message}
              handleClose={() => setUploadStatus({ show: false, message: "" })}
            />
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default FileUpload;
